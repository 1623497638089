import { isNonEmptyArray } from '@Utils/checks';
import { isWhiteColorVariant } from '@Utils/common';

const ColorPill = ({ hexCodes, customStyles = {}, threshold = 250 }) => {
  if (!isNonEmptyArray(hexCodes)) return <></>;

  const isMultiColored = hexCodes.length > 1;

  let whiteColorVariant = false;
  hexCodes.forEach((hexCode) => {
    if (isWhiteColorVariant(hexCode, threshold)) whiteColorVariant = true;
  });

  return (
    <div
      className="h-[15px] w-[15px] rounded-full"
      style={{
        background: isMultiColored ? `linear-gradient(180deg, ${hexCodes[0]} 50%, ${hexCodes[1]} 50%)` : hexCodes[0],
        border: `1px solid ${whiteColorVariant || isMultiColored ? 'black' : hexCodes[0]}`,
        ...customStyles
      }}
    />
  );
};

export default ColorPill;
