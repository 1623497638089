export const FILTER_CONFIG = {
    CATEGORY: 'Category',
    SEPERATOR: '~',
    FILTER_BY_ID: 'filterById~',
    SUBKEY: 'SubKey',
    SUBCATEGORY: 'SubCategory',
    TRIGGER_FILTER_SHEET: 'triggerFilterSheet~',
    SELECT_ALL: 'selectAll~'
  },
  showNewTagForIds = ['delivery_time'],
  excludeNewTagForSubKeys = {
    delivery_time: ['Normal Delivery', 'Fast Delivery']
  },
  renderFilterShowAll = ['sizes'],
  svgIconButtonProps = {
    className: 'pointer-events-none flex items-center justify-center'
  }
