import { FILTER_CONFIG } from '@Containers/Shop/constants';
import { isNonEmptyString } from '@Utils/checks';
import SmallCloseIcon from '@assets/icons/SmallCloseIcon.svg';

const { FILTER_BY_ID, SEPERATOR } = FILTER_CONFIG;

const SizePill = ({ option, appliedFilters, v2 }) => {
  const isActive = appliedFilters?.['sizes']?.includes(option);

  if (!isNonEmptyString(option)) return;
  return (
    <button
      id={FILTER_BY_ID + 'sizes' + SEPERATOR + option}
      className="customButton customButton--noScale p-0 rounded-[24px] text-[14px] font-[500] leading-[20px] text-nm_black active:rounded-[24px] min-w-[48px]"
      style={
        isActive
          ? {
              background: '#F7F7F7',
              border: '1px solid #1A1A1A'
            }
          : { background: '#FFF', border: '1px solid #DDD' }
      }
    >
      <div
        className="pointer-events-none px-[8px] py-[6px] rounded-[24px] active:rounded-[24px] flex items-center justify-center"
        style={
          isActive
            ? {
                border: '0.6px solid #1A1A1A'
              }
            : { border: '0.6px solid #FFF' }
        }
      >
        {option}
        {v2 && isActive && <SmallCloseIcon className='ml-[4px] pointer-events-none'/>}
      </div>
    </button>
  );
};

export default SizePill;
