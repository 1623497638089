import React, { memo, useContext } from 'react';
import QuickFilterItems from './Components';
import { useRouter } from 'next/router';
import { isNonEmptyObject } from '@Utils/checks';
import { handleEventBubbling } from '@Containers/Shop/utils';
import { TrackerContext } from '_app';
import { useAppDispatch, useAppSelector } from '@Store/store';

const QuickFiltersV2 = ({ QuickFiltersData }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const track = useContext(TrackerContext);
  const { activeQuickFilters } = useAppSelector(state => state.shop);

  if (!isNonEmptyObject(QuickFiltersData)) return;

  return (
    <div
      className={"hideScrollBar relative top-[56px] p-[16px] inline-flex gap-[12px] text-nowrap overflow-x-auto w-full max-w-[450px] snap-x snap-mandatory bg-nm_white box-border "}
      onClick={(e) => handleEventBubbling(e, QuickFiltersData, router, dispatch, track, false, activeQuickFilters)}
      style={{
        zIndex: -2,
      }}
    >
      {Object.keys(QuickFiltersData).map((key, idx) => {
        if (!isNonEmptyObject(QuickFiltersData[key])) return;

        return <QuickFilterItems id={key} key={QuickFiltersData[key].title + idx} quickFilterItem={QuickFiltersData[key]} quickFilterKey={key} index={idx} />;
      })}
    </div>
  );
};

export default memo(QuickFiltersV2);
