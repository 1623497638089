import { useRouter } from 'next/router';
import { checkIfFilterActive } from '@Containers/Shop/utils';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/store';
import { addActiveQuickFilters, removeActiveQuickFilters } from '@Containers/Shop/shopSlice';

const QuickFilterItems = ({ id, quickFilterItem, quickFilterKey: key, index }) => {
  const router = useRouter(),
    dispatch = useAppDispatch(),
    { activeQuickFilters } = useAppSelector((state) => state.shop),
    { title, payload } = quickFilterItem ?? {},
    [activeQuickFilter, setActiveQuickFilter] = useState(checkIfFilterActive({ query: router.query, payload: payload }));

  useEffect(() => {
    const isActive = checkIfFilterActive({ query: router.query, payload: payload });
    setActiveQuickFilter(isActive);
    if (isActive && !activeQuickFilters.includes(key)) dispatch(addActiveQuickFilters(key))
    else if(!isActive) dispatch(removeActiveQuickFilters(key));
  }, [router.query]);

  return (
    <div
      id={id}
      data-src={key+'_quick_filters'}
      data-filterType={'quick-filters'}
      data-feedposition={index}
      className={
        'w-max min-w-fit snap-center rounded-[17px] border-solid border-[1px] cursor-pointer bg-nm_white box-border ' +
        (activeQuickFilter ? 'border-nm_black_2' : 'border-nm_light_gray_7')
      }
    >
        {title && <div className={"text-nm_black text-[14px] font-[600] leading-[20px] pointer-events-none border-solid border-[0.4px] px-[8px] py-[4.5px] rounded-[16px] " +
        (activeQuickFilter ? 'border-nm_black_2' : 'border-nm_white')}>{title}</div>}
    </div>
  );
};

export default memo(QuickFilterItems);
