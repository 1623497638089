import React from 'react';

const FilterCount = ({ count }) => {
  return (
    <div className="bg-nm_black text-[10px] leading-[12px] font-bold rounded-full text-nm_white ml-[5px] w-[17px] h-[17px] box-border flex items-center justify-center relative top-[-1px]">
      {count}
    </div>
  );
};

export default FilterCount;
