import { getFilterItems, handleEventBubbling, checkIfFilterActive, searchParamsMapper, saveFilters } from '@Containers/Shop/utils';
import CustomDialog from '@Reducers/CustomModal';
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Amount from '@Components/Amount';
import TickMarkIcon from '@assets/icons/TickMarkIcon.svg';
import RadioCheckedIcon from '@assets/icons/RadioCheckedIcon.svg';
import RadioCheckedIconV2 from '@assets/icons/RadioCheckedIconV2.svg';
import SquareRadioCheckedIcon from '@assets/icons/SquareRadioCheckedIconV2.svg';
import SquareRadioUncheckedIcon from '@assets/icons/SquareRadioUncheckedIconV2.svg';
import RadioUncheckedIcon from '@assets/icons/RadioUncheckedIcon.svg';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString, isUndefinedOrNull } from '@Utils/checks';
import { Slider } from '@mui/material';
import { checkIfAllFiltersLocallySelected, countFiltersApplied, filterKeyApplied, splitArrayByKeyType } from '../../utils';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { setLatestSelectedFilter } from '@Containers/Shop/shopSlice';
import ColorPill from '@Components/ColorPill';
import { FILTER_CONFIG, excludeNewTagForSubKeys, renderFilterShowAll, showNewTagForIds, svgIconButtonProps } from '@Containers/Shop/constants';
import SizeSelectorFilterSheet from '../SizeSelectorFilterSheet';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, SHOP_PAGE_EVENTS } from '@Constants/events';

const { FILTER_BY_ID, SEPERATOR } = FILTER_CONFIG,
      DISABLED_FILTER_BUTTON_STYLE = ' customButton--disable border-none bg-nm_white_smoke text-nm_tertiary_gray ',
      FILTER_BUTTON_STYLES = { transition: 'background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out' };

const getFilterBottomSheetTitle = ({ key, v2 = false }) => {
  switch (key) {
    case 'sort_by':
      return 'Sort by';
    case 'sizes':
      return 'Straight Size';
    case 'filters':
      return v2 ? 'Filter' : 'Filter by';
    default:
      return key;
  }
};

const getFilterButtonTitle = ({ key }) => {
  if (!isNonEmptyString(key)) return key;

  switch (key) {
    case 'min_price':
      return 'Price Range';
    case 'categories':
      return 'Category';
    case 'fabric':
      return 'Clothing Material';
    case 'closure':
      return 'Closure Type';
    default:
      return key.replace(/[_-]/g, ' ');
  }
};

const getFilterOptionsheaderTitle = ({key}) => {
  if (!isNonEmptyString(key)) return key;

  switch (key) {
    case 'sizes': 
      return '';
    case 'min_price':
      return 'PRICE RANGE';
    case 'categories':
      return 'CATEGORY BY';
    case 'fabric':
      return 'MATERIAL TYPE';
    case 'closure':
    case 'pattern':
    case 'occasion':
    case 'neckline':
        return key.replace(/[_-]/g, ' ') + ' TYPE';
    case 'print-type':
    case 'style-type':
    case 'sleeve-type':
        return key.replace(/[_-]/g, ' ');
    case 'delivery_time':
      return 'DELIVERY IN';
    case 'discount':
        return 'DISCOUNT TILL';
    default:
      return key.replace(/[_-]/g, ' ') + ' BY';
  }
}

const FilterOptionsHeader = ({ identifier }) => isNonEmptyString(getFilterOptionsheaderTitle({key: identifier})) && <div className='uppercase pt-[12px] px-[16px] pb-[8px] text-[12px] font-[600] leading-[16px] text-nm_tertiary_gray text-left'>{getFilterOptionsheaderTitle({key: identifier})}</div>

export const FilterButtons = ({ filterKey, v2, clearFilterLabel, disableButtons }: { filterKey?: string, v2?: boolean, clearFilterLabel?: string, disableButtons?: boolean }) => (
  <div
    className="p-[16px] flex gap-[16px] relative bottom-0"
    style={filterKey ? { borderTop: '1px solid #DCDCDC' } : { boxShadow: '0px -1px 5px rgba(0,0,0,0.2)' }}
  >
    {filterKey ? (
      <button
        disabled={disableButtons}
        id={`filterButtons-clear-${filterKey}`}
        className={"customButton customButton--noScale px-[8px] py-[12px] text-nm_black bg-[transparent] rounded-[12px] border-none text-[16px] leading-[24px] font-medium flex-1 active:rounded-[12px] " + (disableButtons ? DISABLED_FILTER_BUTTON_STYLE : "")}
        style={{...FILTER_BUTTON_STYLES,  border: v2 && !disableButtons ? '1px solid #6A6A6A' : '1px solid #FFF'}}
      >
        {clearFilterLabel ?? 'Clear'}
      </button>
    ) : (
      <button
        disabled={disableButtons}
        id="filterButtons-clearAll"
        className={"customButton customButton--noScale px-[8px] py-[12px] text-nm_black bg-[transparent] rounded-[12px] border-none text-[16px] leading-[24px] font-medium flex-1 active:rounded-[12px] " + (disableButtons ? DISABLED_FILTER_BUTTON_STYLE : "")}
        style={FILTER_BUTTON_STYLES}
      >
        Clear filters
      </button>
    )}
    <button
      disabled={disableButtons}
      id="filterButtons-applyFilters"
      className={"customButton customButton--noScale px-[8px] py-[12px] rounded-[12px] border-none text-[16px] leading-[24px] font-medium flex-1 active:rounded-[12px]" + (v2 && !disableButtons ? " bg-nm_black_2" : " bg-nm_primary") + (disableButtons ? DISABLED_FILTER_BUTTON_STYLE : " text-nm_white ")}
      style={{...FILTER_BUTTON_STYLES, border: v2 && !disableButtons ? '1px solid #6A6A6A' : '1px solid #FFF'}}
    >
      Apply
    </button>
  </div>
);

const checkLocalFilterState = ({ appliedFilters }) => {
  if(!isNonEmptyObject(appliedFilters))
    return false;

  const keys = Object.keys(appliedFilters);

  if(keys.length === 1){
    if(isUndefinedOrNull(appliedFilters['p']))
      return true;
    else
      return false;
  }
  else 
    return true;
}

const RenderFilters = ({ filterData, setAppliedFilters, appliedFilters, closeBottomSheet = () => {}}) => {
  const router = useRouter(),
    track = useContext(TrackerContext),
    dispatch = useAppDispatch(),
    filterKeys = Object.keys(filterData),
    { latest_selected_filter: selectedFilter } = useAppSelector((state: RootState) => state.shop),
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { shop_page_v3: shopPageV3 } = webAppDefaultConfig ?? {},
    v2 = shopPageV3 ?? true,
    localFilterStateActive = checkLocalFilterState({ appliedFilters: appliedFilters });

  return (
    <div
      onClick={(e) =>
        saveFilters({ e: e, appliedFilters: appliedFilters, setAppliedFilters: setAppliedFilters, router: router, closeBottomSheet: closeBottomSheet, dispatch: dispatch, track: track })
      }
    >
      <div className="flex items-stretch max-h-[90vh]">
        <div
          className="flex flex-col flex-start w-[70%] max-h-[90vh] hideScrollBar overflow-y-scroll pb-[300px] border-solid border-0 border-r-[1px] border-r-nm_light_gray_9  "
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {filterKeys?.map((key, idx) => {
            if (key === 'max_price' || key === 'sort_by') return;

            const isSelectedFilter = selectedFilter === key,
                  appliedFilter = !isUndefinedOrNull(appliedFilters[key]);

            return (
              <button
                key={key + idx}
                className={
                  'relative customButton customButton--noScale bg-nm_white px-[16px] py-[12px] text-[14px] font-medium leading-[24px] capitalize text-left flex items-center gap-[4px] ' +
                  (isSelectedFilter ? ' text-nm_primary ' : ' text-nm_black ') + 
                  (v2 ? ' pl-[13px] ' : ' pl-[24px] ')
                }
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setLatestSelectedFilter(key));
                }}
              >
                {(v2 !== 0) && <div className={' w-[6px] h-[6px] rounded-full ' + (appliedFilter ? ' bg-nm_primary' : ' bg-nm_white')}/>}
                {getFilterButtonTitle({ key: key })}
                {isSelectedFilter && (v2 ? <div className="h-[36px] my-[6px] w-[8px] absolute top-0 left-[-4px] rounded-[6px] bg-nm_primary" /> : <div className="h-full w-[10px] absolute top-0 left-[-5px] rounded-[6px] bg-nm_primary" />)}
              </button>
            );
          })}
        </div>
        {isNonEmptyString(selectedFilter) &&
          renderFilterBottomSheetUi({
            track: track,
            dispatch: dispatch,
            identifier: selectedFilter,
            filterData: filterData,
            isFiltersSheet: true,
            appliedFilters: appliedFilters,
            setAppliedFilters: setAppliedFilters,
            isAllFiltersSheet: true,
            v2: v2
          })}
      </div>
      <div className="fixed bottom-0 w-full max-w-[450px] bg-nm_white">
        {v2 ? <FilterButtons filterKey={selectedFilter} v2 clearFilterLabel={'Clear filter'} disableButtons={!localFilterStateActive}/> : <FilterButtons />}
      </div>
    </div>
  );
};

const renderFilterBottomSheetUi = ({
  track,
  dispatch,
  identifier,
  filterData,
  isFiltersSheet = false,
  appliedFilters = {},
  setAppliedFilters,
  isAllFiltersSheet = false,
  closeBottomSheet = () => {},
  v2
}) => {
  switch (identifier) {
    case 'filters': {
      return (
        <RenderFilters filterData={filterData} setAppliedFilters={setAppliedFilters} appliedFilters={appliedFilters} closeBottomSheet={closeBottomSheet} />
      );
    }
    case 'min_price': {
      const { max_price, min_price } = appliedFilters ?? {};

      return (
        <div className={'max-h-[90vh] overflow-y-scroll hideScrollBar w-full box-border ' + (isFiltersSheet ? ' pb-[200px]' : '')}>
          {isAllFiltersSheet && <FilterOptionsHeader identifier={identifier} />}
          <div className="text-start px-[16px] py-[12px] box-border">
            <div className="mb-[10px] text-nm_primary text-[14px] font-[600] leading-[20px] flex gap-[3px]">
              <Amount amount={min_price ?? 300} /> - <Amount amount={max_price ?? 4000} />
            </div>
            <div className="m-[10px]">
              <Slider
                sx={{
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff'
                  }
                }}
                min={filterData?.min_price}
                max={filterData?.max_price}
                getAriaLabel={() => 'Temperature range'}
                value={[min_price ?? 300, max_price ?? 4000]}
                valueLabelDisplay="auto"
                onChange={(newValue) => {
                  const [min, max] = newValue?.target?.value ?? [300, 4000];

                  setAppliedFilters({ ...appliedFilters, min_price: min, max_price: max })
                }}
                defaultValue={[500, 1000]}
                step={300}
              />
            </div>
          </div>
        </div>
      );
    }
    case 'sizes': {
      const filterItems = getFilterItems({ key: identifier, filterData: filterData });
      if (!isNonEmptyArray(filterItems)) return <></>;

      const router = useRouter(),
        [tops, bottoms] = splitArrayByKeyType(filterItems),
        { key, isMultiSelect } = searchParamsMapper({ key: identifier }) ?? {},
        renderFilterButtons = isMultiSelect && !isAllFiltersSheet;

      return (
        <div
          className={'max-h-[90vh] overflow-y-scroll hideScrollBar w-full box-border' + (isFiltersSheet ? ' pb-[270px]' : '')}
          onClick={(e) =>
            saveFilters({ e: e, appliedFilters: appliedFilters, setAppliedFilters: setAppliedFilters, router: router, closeBottomSheet: closeBottomSheet, dispatch:dispatch, track: track })
          }
        >
          <div className={(renderFilterButtons ? 'pb-[170px]' : '')}>
            {isAllFiltersSheet && <FilterOptionsHeader identifier={identifier} />}
            {isAllFiltersSheet && <div className={"text-[14px] font-semibold leading-[24px] text-nm_black flex flex-start px-[16px] py-[12px] "+(v2 ? ' text-nm_tertiary_gray ' : ' text-nm_black ')}>TOPS</div>}
            {tops?.map((filterItem, idx) => {
              const isActive = appliedFilters?.[identifier]?.includes(filterItem.key);

              return (
                <FilterSheetItems
                  id={identifier}
                  filterSheetItem={filterItem}
                  key={filterItem.title + idx}
                  isMultiSelect={isMultiSelect}
                  payload={{ [key]: filterItem.key }}
                  isActive={isActive}
                  isAllFiltersSheet={isAllFiltersSheet}
                  saveFilters={renderFilterButtons}
                />
              );
            })}
            {isAllFiltersSheet && <div className={"text-[12px] font-semibold leading-[24px] flex flex-start px-[16px] py-[12px] "+(v2 ? ' text-nm_tertiary_gray' : ' text-nm_black')}>BOTTOMS</div>}
            {bottoms?.map((filterItem, idx) => {
              const { key, isMultiSelect } = searchParamsMapper({ key: identifier }) ?? {},
                filterValue = appliedFilters?.[identifier],
                isActive = isNonEmptyArray(filterValue) ? appliedFilters?.[identifier]?.includes(filterItem.key) : filterValue === filterItem.key;

              return (
                <FilterSheetItems
                  id={identifier}
                  filterSheetItem={filterItem}
                  key={filterItem.title + idx}
                  isMultiSelect={isMultiSelect}
                  payload={{ [key]: filterItem.key }}
                  isActive={isActive}
                  isAllFiltersSheet={isAllFiltersSheet}
                  saveFilters={renderFilterButtons}
                />
              );
            })}
          </div>
          {renderFilterButtons && (
            <div className="fixed bottom-0 w-full max-w-[450px] bg-nm_white">
              <FilterButtons filterKey={identifier} />
            </div>
          )}
        </div>
      );
    }
    default: {
      const filterItems = getFilterItems({ key: identifier, filterData: filterData });
      if (!isNonEmptyArray(filterItems)) return <></>;

      const router = useRouter(),
        { key, isMultiSelect } = searchParamsMapper({ key: identifier }) ?? {},
        renderFilterButtons = isMultiSelect && !isAllFiltersSheet;

      return (
        <div
          className={'max-h-[90vh] overflow-y-scroll hideScrollBar w-full box-border' + (isFiltersSheet ? ' pb-[270px]' : '')}
          onClick={(e) =>
            isFiltersSheet &&
            saveFilters({ e: e, appliedFilters: appliedFilters, setAppliedFilters: setAppliedFilters, router: router, closeBottomSheet: closeBottomSheet, dispatch:dispatch, track: track })
          }
        >
          <div className={renderFilterButtons ? 'pb-[170px]' : ''}>
            {isAllFiltersSheet &&<FilterOptionsHeader identifier={identifier} />}
            {filterItems?.map((filterItem, idx) => {
              const filterValue = appliedFilters?.[identifier],
                isActive = isNonEmptyArray(filterValue) ? appliedFilters?.[identifier]?.includes(filterItem.key) : filterValue === filterItem.key,
                isCategoryFilter = identifier === 'categories' || identifier === 'product_cat',
                subFilterItems = isCategoryFilter && getFilterItems({ key: 'subcategories', filterData: filterData[identifier], filterKey: filterItem.key });

              return (
                <>
                  <FilterSheetItems
                    id={identifier}
                    filterSheetItem={filterItem}
                    key={filterItem.title + idx}
                    isMultiSelect={isMultiSelect}
                    payload={{ [key]: filterItem.key }}
                    isActive={isActive}
                    isAllFiltersSheet={isAllFiltersSheet}
                    saveFilters={renderFilterButtons}
                    useCheckBox={isCategoryFilter}
                  />
                  {isCategoryFilter && isNonEmptyArray(subFilterItems) && isActive && (
                    <div className="ml-[25px]">
                      {subFilterItems.map((subCategory, idx) => {
                        const { key, isMultiSelect } = searchParamsMapper({ key: 'subCategory' }) ?? {},
                          isActive = appliedFilters?.['subCategory']?.includes(subCategory.key);

                        return (
                          <FilterSheetItems
                            id={'subCategory'}
                            filterSheetItem={subCategory}
                            key={subCategory.title + idx}
                            isMultiSelect={isMultiSelect}
                            payload={{ [key]: subCategory.key }}
                            isActive={isActive}
                            isAllFiltersSheet={isAllFiltersSheet}
                            saveFilters={renderFilterButtons}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {renderFilterButtons && (
            <div className="fixed bottom-0 w-full max-w-[450px] bg-nm_white">
              <FilterButtons filterKey={identifier} />
            </div>
          )}
        </div>
      );
    }
  }
};

const TickedOption = ({ checked, isMultiSelect, v2 }) =>
  checked ? (
    isMultiSelect ? (
      v2 ? <SquareRadioCheckedIcon {...svgIconButtonProps}/> : 
      <TickMarkIcon {...svgIconButtonProps} />
    ) : (
      v2 ? <RadioCheckedIconV2 {...svgIconButtonProps} /> : <RadioCheckedIcon {...svgIconButtonProps} /> 
    )
  ) : (
    v2 && isMultiSelect  ? <SquareRadioUncheckedIcon {...svgIconButtonProps}/> : 
      <RadioUncheckedIcon {...svgIconButtonProps} style={{ filter: 'contrast(80%)' }} />
  );

const FilterSheetItems = ({ id, filterSheetItem, payload, isMultiSelect, isActive, isAllFiltersSheet = false, saveFilters = false, useCheckBox = false }) => {
  const router = useRouter(),
    { title, key, hex_codes } = filterSheetItem ?? {},
    [activeQuickFilter, setActiveQuickFilter] = useState(checkIfFilterActive({ query: router.query, payload: payload })),
    optionActive = isAllFiltersSheet || saveFilters ? isActive : activeQuickFilter,
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { shop_page_v3: shopPageV3 } = webAppDefaultConfig ?? {},
    v2 = shopPageV3 ?? true;

  useEffect(() => {
    if (!isAllFiltersSheet && !saveFilters) setActiveQuickFilter(checkIfFilterActive({ query: router.query, payload: payload }));
  }, [router.query]);

  return (
    <button
      className={
        'customButton customButton--noScale w-full bg-nm_white flex justify-between items-center px-[16px] py-[12px] text-[14px] font-normal leading-[24px] ' +
        (isAllFiltersSheet ? (optionActive ? ' text-nm_black ' : ' text-nm_light_gray_11 ') : ' text-nm_black')
      }
      key={key + id}
      title={title}
      id={FILTER_BY_ID + id + SEPERATOR + key}
      data-src={id+'_filter_sheet'}
    >
      <div className="flex items-center justify-center gap-[8px] pointer-events-none">
        <ColorPill hexCodes={hex_codes} />
        <div className='pointer-events-none text-left'>{title}</div>
        {showNewTagForIds.includes(id) && !excludeNewTagForSubKeys[id]?.includes(title) && (
          <div className="bg-nm_red_3 rounded-[8px] text-[8px] leading-[12px] font-bold text-nm_white px-[6px] pt-[4px] pb-[2px] ml-[2px]">NEW</div>
        )}
      </div>
      <div className="pointer-events-none">
        <TickedOption checked={optionActive} isMultiSelect={isMultiSelect || useCheckBox} v2={v2} />
      </div>
    </button>
  );
};

const getSizeSelectorConfig = ({ filterData }) => {
  if (filterData['sizes']) {
    const [tops, bottoms] = splitArrayByKeyType(filterData['sizes']);

    return {
      sizes:{
        tops: tops.map(top => (top?.title ?? top)),
        bottoms:  bottoms.map(bottom => (bottom?.title ?? bottoms)),
      },

      title: 'Select size to customise your shop page'
    };
  }

  return {};
};

const FilterBottomSheet = ({ open, handleClose, filterData, identifier, appliedFilters, setAppliedFilters }) => {
  const router = useRouter(),
    track = useContext(TrackerContext),
    dispatch = useAppDispatch(),
    { key, isMultiSelect } = searchParamsMapper({ key: identifier }),
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { shop_page_v3: shopPageV3 } = webAppDefaultConfig ?? {},
    v2 = shopPageV3 ?? true,
    isAllFiltersSheet = (v2 && identifier === 'filters');

  const handleBottomSheetClose = () => {
    handleClose?.();
    track?.(getTrackingEventObject(-1, SHOP_PAGE_EVENTS.FILTER_SHEET_FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, `${identifier}-sheet-close`, router.asPath));
  }

  return (
    <CustomDialog open={open} isBottomSheet showCloseButton onClose={handleBottomSheetClose} setOpen={handleBottomSheetClose} customCloseIcon={<FilterSheetCloseIcon />}>
      {(v2 === 0) && identifier === 'sizes' ? (
        <SizeSelectorFilterSheet
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          handleBottomSheetClose={handleClose}
          sizeSelectorConfig={getSizeSelectorConfig({filterData: filterData})}
        />
      ) : (
        <div
          className={"max-h-[90vh] hideScrollBar w-[100vw] max-w-[450px] bg-nm_white rounded-t-[24px] " + (v2 && identifier === 'sizes' ? " overflow-hidden overflow-y-scroll " : "")}
          onClick={(e) => identifier != 'filters' && handleEventBubbling(e, filterData, router, dispatch, track)}
        >
          <div className={"px-[16px] py-[24px] text-nm_black border-solid border-0 border-b-[1px] border-b-nm_light_gray_9 text-[20px] font-semibold leading-[28px] text-start flex justify-between items-center" + (v2 && identifier === 'sizes' ? " fixed bg-nm_white w-full max-w-[450px] box-border rounded-t-[24px] " : "")}>
            <div>{getFilterBottomSheetTitle({ key: identifier, v2: v2 })} </div>
            {(isAllFiltersSheet || (renderFilterShowAll.includes(identifier) && isMultiSelect)) ? (
              <button
                id={isAllFiltersSheet ? "filterButtons-clearAll": `selectAll~${identifier}`}
                className={"customButton customButton--noScale text-[14px] leading-[24px] font-[600] bg-[transparent] " + (v2 ? " text-nm_mystique_default " : " text-nm_blue_3 ")}
                onClick={(e) =>
                  saveFilters({
                    e: e,
                    appliedFilters: appliedFilters,
                    setAppliedFilters: setAppliedFilters,
                    router: router,
                    closeBottomSheet: handleClose,
                    filterData: filterData,
                    dispatch: dispatch,
                    track: track,
                  })
                }
              >
                {isAllFiltersSheet ? 'Reset All' :
                checkIfAllFiltersLocallySelected({
                  filterData: filterData[identifier],
                  appliedFilters: appliedFilters[identifier],
                  isMultiSelect: isMultiSelect
                })
                  ? ( v2 ? 'Reset': 'Deselect All')
                  : 'Select All'}
              </button>
            ) : (
              <></>
            )}
          </div>
          {
          (v2 === 1) && identifier === 'sizes' ? (
            <SizeSelectorFilterSheet
              v2
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              handleBottomSheetClose={handleClose}
              sizeSelectorConfig={getSizeSelectorConfig({filterData: filterData})}
            />
          ) : 
          renderFilterBottomSheetUi({
            dispatch: dispatch,
            identifier: identifier,
            filterData: filterData,
            appliedFilters: appliedFilters,
            setAppliedFilters: setAppliedFilters,
            closeBottomSheet: handleClose,
            track: track,
            v2: v2
          })}
        </div>
      )}
    </CustomDialog>
  );
};

export default FilterBottomSheet;
