import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import SizePill from './Components/SizePill';
import { filterKeyApplied, persistSelectedSizes, saveFilters } from '@Containers/Shop/utils';
import { useAppDispatch } from '@Store/store';
import { isNonEmptyArray, isNonEmptyObject, isUndefinedOrNull } from '@Utils/checks';
import SizeMeasureIcon from '@assets/icons/SizeMeasureIcon.svg';
import { FilterButtons } from '../FilterBottomSheet';
import { TrackerContext } from '_app';

const SIZE_FILTER_KEY = 'sizes';

const handleSizeSelectorSheetClick = ({ e, appliedFilters, setAppliedFilters, router, handleClose, dispatch, track}) => {
  const { id } = e?.target ?? {};

  if (id?.includes('filterButtons-')) {
    window.scrollTo(0, 0);
    
    if (id.includes('applyFilters')) persistSelectedSizes({ sizes: appliedFilters[SIZE_FILTER_KEY], dispatch: dispatch });
    else if (id.includes('clear')) persistSelectedSizes({ sizes: [], dispatch: dispatch });
  }

  saveFilters({
    e: e,
    appliedFilters: appliedFilters,
    setAppliedFilters: setAppliedFilters,
    router: router,
    closeBottomSheet: () => handleClose({ userInteracted: true }),
    dispatch: dispatch,
    track: track
  });
};

const SizeSelector = ({ title, options, appliedFilters, v2 }) => {
    if (!isNonEmptyArray(options)) return;
  
    return (
      <div className="text-left">
        {title && <h6 className="uppercase text-[14px] text-nm_tertiary_gray font-[600] leading-[24px] m-0 p-0 mb-[20px] text-left">{title}</h6>}
        <div className="flex gap-[11px] flex-wrap">
          {options.map((option, idx) => (
            <SizePill key={option + idx} option={option} appliedFilters={appliedFilters} v2={v2} />
          ))}
        </div>
      </div>
    );
  };

const SizeSelectorFilterSheet = ({ appliedFilters, setAppliedFilters, handleBottomSheetClose, sizeSelectorConfig, v2 }) => {
  const router = useRouter(),
    dispatch = useAppDispatch(),
    track = useContext(TrackerContext),
    { title, sizes } = sizeSelectorConfig ?? {},
    selectedFilterIsAppliedFilter = filterKeyApplied({ query: router.query, key: SIZE_FILTER_KEY }) || !isUndefinedOrNull(appliedFilters[SIZE_FILTER_KEY]);

  return (
    <div
      className='max-h-full overflow-y-scroll hideScrollBar'
      onClick={(e) =>
        handleSizeSelectorSheetClick({
          e: e,
          appliedFilters: appliedFilters,
          setAppliedFilters: setAppliedFilters,
          router: router,
          handleClose: handleBottomSheetClose,
          dispatch: dispatch,
          track: track
        })
      }
    >
      <div
        className=" px-[16px] py-[24px] w-[100vw] max-w-[450px] box-border rounded-t-[16px] flex flex-col items-start gap-[12px] pb-[90px] text-nm_black bg-nm_white "
        style={ v2 ? { paddingTop: '90px' } : { background: 'linear-gradient(to bottom, #E7F9EE, #FFFFFF)' }}
      >
        {!v2 && <>
          <SizeMeasureIcon />
        {title && <h1 className="text-[20px] text-nm_black_2 font-[700] leading-[28px] m-0 p-0 text-left">{title}</h1>}
        </>}
        <div className={"flex flex-col gap-[32px]" + ( v2 ? " pb-[50px]" : " py-[20px]")}>
          {isNonEmptyObject(sizes) &&
            Object.keys(sizes).map((key, idx) => <SizeSelector key={key + idx} title={key} options={sizes[key]} appliedFilters={appliedFilters} v2={v2} />)}
        </div>
      </div>
      <div className="fixed bottom-0 w-full max-w-[450px] bg-nm_white">
        <FilterButtons filterKey={SIZE_FILTER_KEY} v2={v2} clearFilterLabel={v2 ? 'Clear all' : 'Clear'} disableButtons={v2 ? !selectedFilterIsAppliedFilter : false} />
      </div>
    </div>
  );
};

export default SizeSelectorFilterSheet;
