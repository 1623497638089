import React, { memo, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from '@Utils/useTranslation';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import {  isNonEmptyObject, isNonEmptyString, isNonEmptyArray } from '@Utils/checks';
import QuickFilters from '../QuickFilters';
import { getShopConfigData, updateAppliedFilters } from '@Containers/Shop/utils';
import ArrowExpandIcon from '@assets/icons/ArrowExpandIcon.svg';
import FilterBottomSheet from '../FilterBottomSheet';
import { setAppliedFilters, setFilterSheetOpen } from '@Containers/Shop/shopSlice';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, SHOP_PAGE_EVENTS } from '@Constants/events';
import QuickFiltersV2 from '../QuickFiltersV2';
import FilterCount from '../FilterCount';

export const DEFAULT_MAX_PRICE = 4000,
  DEFAULT_MIN_PRICE = 300;

const FilterControls = ({ filterData, shopConfig, filtersAppliedCount, shopPageExp, enable = true, v2 = false }) => {
  const router = useRouter();
  const { query } = router;
  const track = useContext(TrackerContext);
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [shopConfigData, setShopConfigData] = useState({});
  const { appliedFilters } = useAppSelector((state: RootState) => state.shop);
  const { quick_filters } = isNonEmptyObject(shopConfig) ? shopConfig : shopConfigData;
  const { filter_sheet_open : filterBottomSheet } = useAppSelector((state: RootState) => state.shop);
  const sizeFilterVal = query['size'];
  const sizeFiltersCount = isNonEmptyArray(sizeFilterVal) ? sizeFilterVal?.length : isNonEmptyString(sizeFilterVal) ? sizeFilterVal?.split(',')?.length : 0;

  useEffect(()=>{
    setShopConfigData(getShopConfigData());
  }, []);

  useEffect(() => {
    dispatch(setAppliedFilters(updateAppliedFilters({ filterData: filterData, query: router.query })));
  }, [router.query]);

  const handleFilterButtonClick = (id) => {
    dispatch(setFilterSheetOpen(id))
    
    if(isNonEmptyString(id))
      track(getTrackingEventObject(0, SHOP_PAGE_EVENTS.SHOP_FILTER_BAR_FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, id+'_sheet', router.pathname));
  }

  return (
    <>
      <div className='bg-nm_white z-[-2]'>
      {<div className='flex border-solid border-nm_light_gray_8 border-t-[0] border-b-[1px] border-x-0 z-[2] relative' style={{ ...(shopPageExp && !enable ? { position: 'sticky', top: 0 } : { top: 56 })}}>
        <button
          className='w-full bg-nm_white flex items-center justify-center customButton customButton--noScale text-nm_black px-[16px] py-[12px] text-[14px] font-semibold leading-[20px]'
          onClick={()=> handleFilterButtonClick('sort_by')}
        >
            <div>SORT</div>
          <ArrowExpandIcon className='ml-[4px]' />
        </button>
          <button
            className='w-full bg-nm_white flex items-center justify-center customButton customButton--noScale text-nm_black px-[16px] py-[12px] text-[14px] font-semibold leading-[20px]'
            onClick={()=> handleFilterButtonClick('sizes')}
          >
            SIZES
            {v2 && sizeFiltersCount > 0 && <FilterCount count={sizeFiltersCount} />}
            <ArrowExpandIcon className='ml-[4px]'/>
          </button>
        <button className='w-full bg-nm_white flex items-center justify-center customButton customButton--noScale text-nm_black px-[16px] py-[12px] text-[14px] font-semibold leading-[20px]' disabled={!filterData} onClick={()=> handleFilterButtonClick('filters')}>
          <div className='flex items-center justify-center'>
            {translate('filters').toUpperCase()}
            {filtersAppliedCount > 0 && <FilterCount count={filtersAppliedCount} />}
          </div>
          <ArrowExpandIcon className='ml-[4px]' />
        </button>
      </div>}

      {enable && <QuickFiltersV2 QuickFiltersData={quick_filters} />}

      </div>

      <FilterBottomSheet open={isNonEmptyString(filterBottomSheet)} handleClose={()=> dispatch(setFilterSheetOpen(''))} filterData={shopConfig?.filters ?? filterData} identifier={filterBottomSheet} appliedFilters={appliedFilters} setAppliedFilters={(data) => 
        dispatch(setAppliedFilters(data))} />
    </>
  );
};

export default memo(FilterControls);
