import { store } from '@Store/store';
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString, isServer, sessionStorageIsSupported } from '@Utils/checks';
import { SessionStorage } from '@Utils/storage';
import { SELECT_SIZE_MODAL_SHOWN, SHOP_CONFIG_KEY, USER_SELECTED_SIZES } from '@Constants/storageKeys';
import { setFilterSheetOpen, setLatestSelectedFilter, setSelectSizeModalShown, setUserSelectedSizes } from './shopSlice';
import { FILTER_CONFIG } from './constants';
import { capitalizeFirst, getTrackingEventObject, handleScrollToTop } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, SHOP_PAGE_EVENTS } from '@Constants/events';

const { FILTER_BY_ID, SEPERATOR, TRIGGER_FILTER_SHEET, SELECT_ALL } = FILTER_CONFIG;

export const searchParamsMapper = ({ key, isSearchParamKey = false }) => {
  if (isSearchParamKey) {
    switch (key) {
      case 'orderby':
        return {
          key: 'sort_by'
        };
      case 'collection':
        return {
          key: 'tag',
          isMultiSelect: true
        };
      case 'color':
        return {
          key: 'colors',
          isMultiSelect: true
        };
      case 'size':
        return {
          key: 'sizes',
          isMultiSelect: true
        };
      case 'product_cat':
        return {
          key: 'categories'
        };
      case 'style_type':
        return {
          key: 'style-type',
          isMultiSelect: true
        };
      case 'sleeve_type':
        return {
          key: 'sleeve-type',
          isMultiSelect: true
        };
      case 'price': 
        return {
          key: 'min_price',
      };
      case 'print_type':
        return {
          key: 'print-type',
          isMultiSelect: true
        };
      case 'subCategory':
      case 'discount':
      case 'price':
      case 'delivery_time':
      case 'search_term':
        return {
          key: key
        };
      default:
        return {
          key: key,
          isMultiSelect: true
        };
    }
  } else {
    switch (key) {
      case 'sort_by':
        return {
          key: 'orderby'
        };
      case 'tag':
        return {
          key: 'collection',
          isMultiSelect: true
        };
      case 'colors':
        return {
          key: 'color',
          isMultiSelect: true
        };
      case 'sizes':
        return {
          key: 'size',
          isMultiSelect: true
        };
      case 'min_price': 
        return {
          key: 'price',
      };
      case 'categories':
        return {
          key: 'product_cat'
        };
      case 'style-type':
        return {
          key: 'style_type',
          isMultiSelect: true
        };
      case 'sleeve-type':
        return {
          key: 'sleeve_type',
          isMultiSelect: true
        };
      case 'print-type':
        return {
          key: 'print_type',
          isMultiSelect: true
        };
      case 'orderby':
      case 'product_cat':
      case 'subCategory':
      case 'discount':
      case 'price':
      case 'delivery_time':
      case 'search_term':
        return {
          key: key
        };
      default:
        return {
          key: key,
          isMultiSelect: true
        };
    }
  }
};

export const getShopConfigData = () => {
  if (!isServer()) {
    if (sessionStorageIsSupported()) return JSON.parse(SessionStorage.getItem(SHOP_CONFIG_KEY)) ?? {};
    else {
      const { shop } = store.getState();
      return shop?.shop_config_data ?? {};
    }
  } else return {};
};

export const getFilterItems = ({ key, filterData, filterKey = '' }) => {
  switch (key) {
    case 'categories': {
      const categoryKeys = isNonEmptyObject(filterData?.[key]) ? Object.keys(filterData[key]).map((key) => ({ key: key, title: key })) : [];
      return categoryKeys;
    }
    case 'subcategories': {
      const categoryKeys =
        isNonEmptyString(filterKey) && isNonEmptyArray(filterData?.[filterKey]) ? filterData[filterKey].map((key) => ({ key: key, title: key })) : [];
      return categoryKeys;
    }
    case 'colors': {
      const categoryKeys = isNonEmptyArray(filterData?.[key])
        ? filterData[key].map(({ name, hex_codes }) => ({ key: name, title: name, hex_codes: hex_codes }))
        : [];
      return categoryKeys;
    }
    case 'sizes': {
      const categoryKeys = isNonEmptyArray(filterData?.[key])
        ? filterData[key].map(({ key, meta_data }) => ({ key: key, title: key, meta_data: meta_data }))
        : [];
      return categoryKeys;
    }
    default:
      return filterData?.[key] ?? [];
  }
};

export const filterKeyApplied = ({ query, key }) => {
  const searchParamKey = searchParamsMapper({ key: key }).key,
        searchParamVal = key === 'categories' ? (query['categoryName'] || query[searchParamKey]) : query[searchParamKey];

  return searchParamVal ? isNonEmptyArray(searchParamVal) ? searchParamVal.length > 0 : isNonEmptyString(searchParamVal) : false;
}

export const checkIfFilterActive = ({ query, payload }) => {
  const payloadIsNonEmptyObject = isNonEmptyObject(payload),
    keysArray = payloadIsNonEmptyObject ? Object.keys(payload) : [];

  let isActive = payloadIsNonEmptyObject ? true : false;

  for (let i = 0; i < keysArray.length; i++) {
    const searchParamKey = searchParamsMapper({ key: keysArray[i] }).key,
      searchParamArr = isNonEmptyArray(query[searchParamKey]) ? query[searchParamKey] : query[searchParamKey]?.split(','),
      payloadValue = payload[keysArray[i]];

    if (isNonEmptyArray(payloadValue)) {
      for (let j = 0; j < payloadValue.length; j++) if (searchParamArr?.includes(payloadValue[j])) return true;

      return false;
    } else if (!searchParamArr?.includes(payload[keysArray[i]])) {
      return false;
    }
  }

  return isActive;
};

export const validateQuickFilters = ({ params }) => {
  const { shop } = store.getState(),
  {activeQuickFilters: appliedQuickFilters} = shop ?? {},
  filterConfig = getShopConfigData(),
  { quick_filters: quickFilters } = filterConfig;

  if (isNonEmptyArray(appliedQuickFilters)) {
    
    for (let i = 0; i < appliedQuickFilters?.length; i++) {
      const key = appliedQuickFilters[i],
            { payload } = quickFilters[key] || {};
            
      if (isNonEmptyObject(payload)) {
        const payloadKeys = Object.keys(payload);
        
        let filterValid = true;
        if(!checkIfFilterActive({ query: params, payload: payload }))
            filterValid = false;

        if(!filterValid){
          for (let j = 0; j < payloadKeys.length; j++) {
            const currKey = payloadKeys[j],
                  { isMultiSelect } = searchParamsMapper({ key: currKey, isSearchParamKey: true });

            if (isMultiSelect) {
                const searchParamQuery = params?.[currKey],
                    searchParamArr = isNonEmptyArray(searchParamQuery) ? searchParamQuery : isNonEmptyString(searchParamQuery) ? searchParamQuery.split(',') : [],
                    payloadVal = isNonEmptyArray(payload[currKey]) ? payload[currKey] : isNonEmptyString(payload[currKey]) ? payload[currKey].split(',') : [];

                if (isNonEmptyArray(searchParamArr) && isNonEmptyArray(payloadVal)) {
                    const filterVal = searchParamArr.filter(item => !payloadVal.includes(item)).join(',');
        
                    if (isNonEmptyString(filterVal))
                        params[currKey] = filterVal;
                    else 
                        delete params[currKey];
                }
                else if (params[currKey] === payload[currKey]) {
                    delete params[currKey];
                }
            }
          } 
        }
      }
    }
  }
}

export const handleEventBubbling = (e, filtersData, router, dispatch = (action) => {}, track, singleSelectWithReset = false, appliedQuickFilters2 = []) => {
  const { query, replace } = router,
    { id, dataset } = e.target ?? {};

  if (id?.includes(SELECT_ALL)) {
    const filterKey = id.replace(SELECT_ALL, ''),
      filterValues = filtersData[filterKey],
      { key } = searchParamsMapper({ key: filterKey }),
      filterOptions = toggleSelectAll({ id: filterKey, filterData: filterValues, query: query, searchParamsKey: key }),
      searchParams = query;

    if (isNonEmptyArray(filterOptions)) searchParams[key] = filterOptions.join(',');
    else delete searchParams[key];

    const { finalPathname, finalSearchParams } = getNavigationConfig({ router: router, searchParams: searchParams });
    updateShopUrl({ finalPathname: finalPathname, finalSearchParams: finalSearchParams, replace: replace });
  } else if (id?.includes(TRIGGER_FILTER_SHEET)) {
    const filterKey = id.replace(TRIGGER_FILTER_SHEET, '');

    dispatch(setLatestSelectedFilter(filterKey));
    dispatch(setFilterSheetOpen('filters'));
  } else if (isNonEmptyObject(filtersData) || id?.includes(FILTER_BY_ID)) {
    window.scrollTo(0, 0);

    let payloadObj = {};

    if (id?.includes(FILTER_BY_ID)) {
      const [filter, value] = id.replace(FILTER_BY_ID, '').split(SEPERATOR) ?? [];
      payloadObj = { [filter]: value };
    } else {
      const { payload } = filtersData[id] ?? {};
      payloadObj = payload;
    }
    const isActive = checkIfFilterActive({ query: query, payload: payloadObj });
    let tempQuery = { p: '1' };

    query['p'] = 1;

    const keysArray = isNonEmptyObject(payloadObj) ? Object.keys(payloadObj) : payloadObj;

    if (!isActive)
      track(
        getTrackingEventObject(
          dataset?.feedposition ?? -1,
          dataset?.filtertype ?? SHOP_PAGE_EVENTS.FILTER_SHEET_FEED_TYPE,
          GLOBAL_ACTION_TYPES.CLICK,
          dataset?.src,
          router.asPath,
          {
            meta: { filters: payloadObj }
          }
        )
      );

    for (let i = 0; i < keysArray.length; i++) {
      const { key: searchParam, isMultiSelect } = searchParamsMapper({ key: keysArray[i] }),
        searchParamsValue = query[searchParam],
        payloadValue = payloadObj[keysArray[i]];

      if (searchParam === 'product_cat') delete query['subCategory'];

      if (isActive) {
        if (singleSelectWithReset) {
        } else if (isMultiSelect) {
          const searchParamsArray = isNonEmptyString(searchParamsValue) && searchParamsValue.split(','),
            finalSearchParamsArray =
              isNonEmptyArray(searchParamsArray) &&
              searchParamsArray.filter((params) => {
                if (isNonEmptyArray(payloadValue)) {
                  for (let j = 0; j < payloadValue.length; j++) {
                    if (payloadValue[j] === params) return false;
                  }

                  return true;
                } else return params !== payloadValue;
              });

          if (isNonEmptyArray(finalSearchParamsArray)) query[searchParam] = finalSearchParamsArray.join(',');
          else delete query[searchParam];
        } else delete query[searchParam];
      } else {
        if (singleSelectWithReset) {
          tempQuery[searchParam] = payloadValue;
        }
        if (isMultiSelect) {
          if (isNonEmptyString(searchParamsValue)) query[searchParam] = searchParamsValue + ',' + payloadValue;
          else query[searchParam] = payloadValue;
        } else query[searchParam] = payloadValue;
      }
    }

    validateQuickFilters({ params: query });

    const { finalPathname, finalSearchParams } = getNavigationConfig({ router: router, searchParams: singleSelectWithReset ? tempQuery : query });
    updateShopUrl({ finalPathname: finalPathname, finalSearchParams: finalSearchParams, replace: replace });
  }
};

export const updateShopUrl = ({ replace, finalPathname, finalSearchParams }) =>
  replace(
    {
      pathname: finalPathname,
      query: finalSearchParams,
      hash: window.location.hash
    },
    undefined,
    { shallow: true }
  );

export const parseFilterItem = ({ key, filterItem }) => {
  switch (key) {
    case 'colors':
      return { key: filterItem?.name };
    default:
      return { key: filterItem?.key };
  }
};

export const checkIfAllFiltersSelected = ({ id, searchParamsKey, isMultiSelect, filterData, query }) => {
  if (!filterData?.[id] || !isNonEmptyArray(filterData[id]) || !isMultiSelect) return false;

  const searchParamsValue = query[searchParamsKey],
    searchParamsArr = searchParamsValue ? (isNonEmptyString(searchParamsValue) ? searchParamsValue.split(',') : searchParamsValue) : [];

  return isNonEmptyArray(searchParamsArr) ? searchParamsArr.length === filterData[id].length : false;
};

export const checkIfAllFiltersLocallySelected = ({ appliedFilters, filterData, isMultiSelect }) => {
  if (!isNonEmptyArray(filterData) || !isMultiSelect) return false;

  return isNonEmptyArray(appliedFilters) ? appliedFilters.length === filterData.length : false;
};

export const toggleSelectAll = ({ id, searchParamsKey, filterData, query }) => {
  if (!isNonEmptyArray(filterData)) return [];

  const searchParamsValue = query[searchParamsKey],
    searchParamsArr = searchParamsValue ? (isNonEmptyString(searchParamsValue) ? searchParamsValue.split(',') : searchParamsValue) : [],
    filterOptions = [];

  let allOptionsSelected = searchParamsValue ? true : false;

  filterData.forEach((filter) => {
    const filterItem = parseFilterItem({ key: id, filterItem: filter }).key;
    filterOptions.push(filterItem);

    if (!searchParamsArr.includes(filterItem)) allOptionsSelected = false;
  });

  return allOptionsSelected ? [] : filterOptions;
};

export const toggleLocalSelectAll = ({ id, appliedFilters, filterData }) => {
  if (!isNonEmptyArray(filterData)) return [];

  const filtersArr = isNonEmptyArray(appliedFilters?.[id]) ? appliedFilters[id] : [],
    filterOptions = [];

  let allOptionsSelected = appliedFilters ? true : false;

  filterData.forEach((filter) => {
    const filterItem = parseFilterItem({ key: id, filterItem: filter }).key;
    filterOptions.push(filterItem);

    if (!filtersArr.includes(filterItem)) allOptionsSelected = false;
  });

  return allOptionsSelected ? [] : filterOptions;
};

export const sanitisePathname = ({ pathname }: { pathname: string }) => {
  if (pathname.includes('/')) {
    return pathname.replace('/', '-');
  }

  return pathname;
};

export const sanitiseCategoryName = ({ categoryName }: { categoryName?: string }) => {
  if (isNonEmptyString(categoryName) && categoryName === 'jumpsuits-bodysuits') {
    return 'Jumpsuits/Bodysuits';
  }

  return categoryName;
};

export const getNavigationConfig = ({ router, searchParams }) => {
  const { query, pathname } = router;
  const { categoryName, tag } = query;
  let { subCategory, product_cat } = searchParams ?? {};

  if (isNonEmptyArray(subCategory)) subCategory = subCategory[0];
  if (isNonEmptyArray(product_cat)) product_cat = product_cat[0];

  if (categoryName) {
    delete searchParams['categoryName'];

    if (isNonEmptyString(subCategory))
      return { finalPathname: `/womens-collection/${sanitisePathname({ pathname: subCategory.toLowerCase() })}`, finalSearchParams: searchParams };
    else if (isNonEmptyString(product_cat))
      return { finalPathname: `/womens-collection/${sanitisePathname({ pathname: product_cat.toLowerCase() })}`, finalSearchParams: searchParams };
    else return { finalPathname: `/womens-collection/${sanitisePathname({ pathname: categoryName })}`, finalSearchParams: searchParams };
  } else if (tag) return { finalPathname: `/collection/${sanitisePathname({ pathname: tag })}`, finalSearchParams: searchParams };

  return { finalPathname: pathname, finalSearchParams: searchParams };
};

export const splitArrayByKeyType = (array) => {
  if (!isNonEmptyArray(array)) return [];

  const alphanumericArray = [];
  const numericArray = [];

  array.forEach((item) => {
    if (/^\d+$/.test(item.key)) {
      numericArray.push(item);
    } else {
      alphanumericArray.push(item);
    }
  });

  return [alphanumericArray, numericArray];
};

export const countFiltersApplied = ({ filterData, query }) => {
  if (!isNonEmptyObject(filterData) || !isNonEmptyObject(query)) return 0;

  let appliedFilters = 0;
  const filterKeys = Object.keys(query);

  filterKeys.forEach((filterKey) => {
    const { key } = searchParamsMapper({ key: filterKey, isSearchParamKey: true });

    if (
      key !== 'sort_by' && (
      (filterData[key] != null && isNonEmptyString(query[filterKey])) ||
      (filterKey === 'price' && isNonEmptyString(query['price'])) ||
      (filterKey === 'subCategory' && isNonEmptyString(query['subCategory'])))
    )
      appliedFilters++;
    else if (filterKey === 'categoryName') {
      const filterValue = query[filterKey],
        categoryName = isNonEmptyArray(filterValue) ? filterValue[0] : filterValue;

      const { category, subCategory } = parseCategoryName({
        filterData: filterData?.['categories'],
        categoryName: capitalizeFirst(sanitiseCategoryName({ categoryName: categoryName }))
      });

      if (category && !query['product_cat']) appliedFilters++;
      if (subCategory && !query['subCategory']) appliedFilters++;
    }
  });

  return appliedFilters;
};

export const parseCategoryName = ({ filterData, categoryName }) => {
  if (isNonEmptyObject(filterData) && isNonEmptyString(categoryName)) {
    const categoryKeys = Object.keys(filterData);

    for (let i = 0; i < categoryKeys.length; i++) {
      const category = categoryKeys[i];

      if (category === categoryName) return { category: category };
      else if (isNonEmptyArray(filterData[category]) && filterData[category].includes(categoryName)) return { category: category, subCategory: categoryName };
    }
  }

  return {};
};

export const updateAppliedFilters = ({ filterData, query }) => {
  const filterKeys = Object.keys(query),
    appliedFilters = {};

  if (isNonEmptyArray(filterKeys)) {
    filterKeys.map((filterKey) => {
      const { key, isMultiSelect } = searchParamsMapper({ key: filterKey, isSearchParamKey: true }),
        filterValue = query[filterKey];

      if (filterKey === 'categoryName') {
        const categoryName = isNonEmptyArray(filterValue) ? filterValue[0] : filterValue;
        const { category, subCategory } = parseCategoryName({
          filterData: filterData?.['categories'],
          categoryName: capitalizeFirst(sanitiseCategoryName({ categoryName: categoryName }))
        });

        if (category) appliedFilters['categories'] = [category];
        if (subCategory) appliedFilters['subCategory'] = [subCategory];
      } else if (filterKey === 'price' && isNonEmptyString(filterValue)) {
        const [min_price, max_price] = filterValue.split(',');

        appliedFilters['min_price'] = min_price ?? 300;
        appliedFilters['max_price'] = max_price ?? 4000;
      } else if (isNonEmptyString(filterValue))
        if (isMultiSelect) appliedFilters[key] = filterValue.split(',');
        else appliedFilters[key] = filterValue;
    });
  }

  return appliedFilters;
};

export const isFilterActive = ({ appliedFilters, filterKey, filterValue, isMultiSelect }) => {
  const appliedFiltersIsNonEmptyObject = isNonEmptyObject(appliedFilters);

  if (appliedFiltersIsNonEmptyObject) {
    const appliedFilter = appliedFilters[filterKey];

    if (isMultiSelect && isNonEmptyArray(appliedFilter)) return appliedFilter.includes(filterValue);
    else return appliedFilter === filterValue;
  }

  return false;
};

export const updateSearchParams = ({ appliedFilters, params }) => {
  const excludedSearchParamKeys = ['search_term'];

  if (!isNonEmptyObject(appliedFilters)) return;

  const filterKeys = Object.keys(appliedFilters);
  const searchParamKeys = Object.keys(params);

  searchParamKeys.forEach((searchParamKey) => {
    if (excludedSearchParamKeys.includes(searchParamKey)) return;

    const { key } = searchParamsMapper({ key: searchParamKey, isSearchParamKey: true });

    if (appliedFilters[key] === null || appliedFilters[key] === undefined) delete params[searchParamKey];
  });

  filterKeys.forEach((filterKey) => {
    const { key, isMultiSelect } = searchParamsMapper({ key: filterKey }),
      filterValue = appliedFilters[filterKey];

    if (filterKey === 'min_price') return;
    else if (filterKey === 'max_price') {
      params['price'] = [appliedFilters['min_price'] ?? 300, filterValue].join(',');
      return;
    }

    if (isMultiSelect && isNonEmptyArray(filterValue)) params[key] = filterValue.join(',');
    else params[key] = filterValue;
  });
};

export const saveFilters = ({ e, appliedFilters, setAppliedFilters, router, closeBottomSheet, filterData = {}, dispatch, track }) => {
  e.preventDefault();
  e.stopPropagation();

  const { id, dataset } = e.target ?? {};

  if (id?.includes(FILTER_BY_ID)) {
    const [key, filter] = id.replace(FILTER_BY_ID, '').split(SEPERATOR);
    const { isMultiSelect } = searchParamsMapper({ key: key });

    const isActive = isFilterActive({ appliedFilters: appliedFilters, filterKey: key, filterValue: filter, isMultiSelect: isMultiSelect });
    const currFilters = { ...appliedFilters };

    if (isActive) {
      if (key === 'categories') delete currFilters['subCategory'];

      if (isMultiSelect) {
        const filtersArray = currFilters[key],
          finalFiltersArray = isNonEmptyArray(filtersArray) && filtersArray.filter((params) => params !== filter);

        if (isNonEmptyArray(finalFiltersArray)) currFilters[key] = finalFiltersArray;
        else delete currFilters[key];
      } else delete currFilters[key];

      setAppliedFilters(currFilters);
    } else {
      if (isMultiSelect) {
        if (isNonEmptyArray(appliedFilters[key])) {
          const currValue = [...currFilters[key]];
          if (key === 'categories') delete currFilters['subCategory'];

          currValue.push(filter);

          setAppliedFilters({ ...currFilters, [key]: currValue });
        } else {
          const currFilters = { ...appliedFilters };
          if (key === 'categories') delete currFilters['subCategory'];

          setAppliedFilters({ ...currFilters, [key]: [filter] });
        }
      } else {
        const currFilters = { ...appliedFilters };
        if (key === 'categories') delete currFilters['subCategory'];

        setAppliedFilters({ ...currFilters, [key]: [filter] });
      }
    }
  } else if (id?.includes('selectAll~')) {
    const [identifier] = id.replace('selectAll~', '').split(SEPERATOR),
      filterOptions = toggleLocalSelectAll({ id: identifier, filterData: filterData[identifier], appliedFilters: appliedFilters });

    setAppliedFilters(
      (() => {
        const currFilters = { ...appliedFilters };

        if (isNonEmptyArray(filterOptions)) currFilters[identifier] = filterOptions;
        else delete currFilters[identifier];

        return currFilters;
      })()
    );
  } else if (id?.includes('filterButtons-')) {
    window.scrollTo(0, 0);

    let params = { ...router.query };

    if (id.includes('applyFilters')) {
      params['p'] = '1';

      const sizes = appliedFilters['sizes'];

      if (sizes && sizes?.length > 0) persistSelectedSizes({ sizes: sizes, dispatch: dispatch });
      else persistSelectedSizes({ sizes: [], dispatch: dispatch });

      updateSearchParams({ appliedFilters: appliedFilters, params: params });

      track?.(
        getTrackingEventObject(-1, dataset?.filtertype ?? SHOP_PAGE_EVENTS.ALL_FILTERS_SHEET_FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, 'apply-filters', router.asPath, {
          meta: { filters: appliedFilters }
        })
      );
    } else if (id.includes('clearAll')) {
      params = { p: '1' };
      persistSelectedSizes({ sizes: [], dispatch: dispatch });

      track?.(getTrackingEventObject(-1, dataset?.filtertype ?? SHOP_PAGE_EVENTS.ALL_FILTERS_SHEET_FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, 'clear-all-filters', router.asPath));
    } else if (id.includes('clear')) {
      const key = id.replace('filterButtons-clear-', ''),
        currFilters = { ...appliedFilters };

      delete currFilters[key];

      // TODO: needs to be refactored
      if(key === 'min_price')
        delete currFilters['max_price'];

      updateSearchParams({ appliedFilters: currFilters, params: params });

      track?.(
        getTrackingEventObject(
          -1,
          dataset?.filtertype ?? SHOP_PAGE_EVENTS.FILTER_SHEET_FEED_TYPE,
          GLOBAL_ACTION_TYPES.CLICK,
          'clear-filter',
          router.asPath,
          appliedFilters[key] && { meta: { filter: { [key]: appliedFilters[key] } } }
        )
      );
    }

    validateQuickFilters({ params: params });

    const { finalPathname, finalSearchParams } = getNavigationConfig({ router: router, searchParams: { ...params, p: '1' } });
    updateShopUrl({ finalPathname: finalPathname, finalSearchParams: finalSearchParams, replace: router.replace });

    closeBottomSheet?.();
  }
};

export const updateAppliedSizeFilters = ({ query }) => {
  const sizes = query['size'];

  if (sizes) {
    return isNonEmptyString(sizes) ? sizes.split(',') : sizes;
  }

  return [];
};

export const persistSelectedSizes = ({ sizes, dispatch }) => {
  if (!sizes) sizes = [];

  if (sessionStorageIsSupported()) SessionStorage.setItem(USER_SELECTED_SIZES, JSON.stringify(sizes));
  else dispatch(setUserSelectedSizes(sizes));
};

export const getUserSelectedSizes = () => {
  if (sessionStorageIsSupported()) {
    const storedSizes = SessionStorage.getItem(USER_SELECTED_SIZES);
    return storedSizes ? JSON.parse(storedSizes) : null;
  } else {
    const { shop } = store.getState(),
      { user_selected_sizes } = shop ?? {};

    return user_selected_sizes;
  }
};

export const persistSelectSizeModalShown = ({ dispatch }) => {
  if (sessionStorageIsSupported()) SessionStorage.setItem(SELECT_SIZE_MODAL_SHOWN, JSON.stringify(true));
  else dispatch(setSelectSizeModalShown(true));
};

export const getSelectSizeModalShown = () => {
  if (sessionStorageIsSupported()) return SessionStorage.getItem(SELECT_SIZE_MODAL_SHOWN);
  else {
    const { shop } = store.getState(),
      { select_size_modal_shown } = shop ?? {};

    return select_size_modal_shown;
  }
};
